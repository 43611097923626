import { gql } from '@apollo/client';

export const MAIL_MUTATION = gql`
  mutation Mail($input: LeadInput!) {
    lead(input: $input) {
      mail {
        success
      }
    }
  }
`;
