import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Container } from '../../layout/Container';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { useMutation } from '@apollo/client';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { SiteContext } from '../../../utils/context/SiteContext';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { useLocation } from 'react-router';
import { Mail, MailVariables } from '../../../__types__/generated/Mail';
import { MAIL_MUTATION } from '../../../../client/__graphql__/mutations/mail';
import { Thanks } from '../../layout/Thanks';
import { Section } from '../../layout/Section';

const Bekkelaget: React.FC = () => {
  const location = useLocation();
  const { routeConfig } = React.useContext(SiteContext);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [mail, { error }] = useMutation<Mail, MailVariables>(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      setLoading(false);
      if (lead?.mail?.success) {
        setPosted(true);
      }
    }
  });

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Ola Nordmann',
        value: '',
        label: 'Navn'
      },
      {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      setLoading(true);

      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: `${location.pathname}${location.search}`,
            leadType: 'EPOST',
            mailType: 92
          }
        }
      });
    }
  });

  return (
    <MaripaaveienStyle>
      <Container>
        <Heading tag="h1" center={true}>
          Bekkelaget skolekrets
        </Heading>

        <Paragraph center={true}>
          Kunde vil kjøpe en bolig som din på Bekkelaget.
        </Paragraph>
        <Paragraph center={true}>
          På vegne av kunde søker vi etter en enebolig i området innenfor
          Bekkelaget skolekrets. <br /> Alt av interesse!
        </Paragraph>
        <Paragraph center={true}>
          Ta kontakt hvis du vurderer salg av bolig.
        </Paragraph>

        {/*         <img
          className="bekkelaget"
          src="https://cdn.reeltime.no/pm_assets/kampanjer/maripaaveien/maripaaveien.jpg"
          alt=""
        /> */}

        <Paragraph center={true}>
          Anders Hartwig Thoresen
          <div className="contact">
            <div className="tel">
              {' '}
              Telefon <a href="tel: 93459150"> 93 45 91 50</a>{' '}
            </div>
            <div className="email">
              {' '}
              E-post
              <a href="mailto:anders@privatmegleren.no">
                {' '}
                anders@privatmegleren.no
              </a>
            </div>
          </div>
        </Paragraph>
        <FormWrapper style={fadeOut}>
          <Form onSubmit={handleSubmit} noValidate>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields.map((item: any, index: number) => {
              return (
                <FormRow key={`field_${index}`}>
                  <Input
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    labelAfter={item.labelAfter}
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={handleChange}
                    error={item.error}
                  />
                </FormRow>
              );
            })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig?.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg innen kort tid for å
              avtale en befaring.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </MaripaaveienStyle>
  );
};

const MaripaaveienStyle = styled.div`
  padding-top: 7em;
  .bekkelaget {
    object-fit: contain;
    width: 100%;
    padding: 2em 0 2em;
  }

  @media all and(min-width:600px) {
    .bekkelaget {
      object-fit: contain;
      width: 100%;
      padding: 3em;
    }
  }
`;

const ButtonWrapper = styled.div`
  .meldinteresse {
    text-align: center;
  }
  @media all and(min-width:600px) {
    .meldinteresse {
      text-align: center;
    }
  }
`;

export default Bekkelaget;
